import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createBlock(_component_router_link, {
    to: "/"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 -34 752 752",
      width: "40"
    }, [_createElementVNode("g", {
      fill: "none"
    }, [_createElementVNode("path", {
      fill: "#B71010",
      d: "M61.446 31.534L375.466 0v682.667l-314.02-31.534C26.56 647.629 0 618.269 0 583.208V99.459c0-35.061 26.56-64.422 61.446-67.925z",
      transform: "translate(-136 -170) translate(136.533 170.667)"
    }), _createElementVNode("path", {
      fill: "#D51C1C",
      d: "M436.912 31.534L750.933 0v682.667l-314.02-31.534c-34.886-3.504-61.446-32.864-61.446-67.925V99.459c0-35.061 26.56-64.422 61.445-67.925z",
      transform: "translate(-136 -170) translate(136.533 170.667) matrix(-1 0 0 1 1126.4 0)"
    }), _createElementVNode("path", {
      fill: "#FFF",
      "fill-rule": "nonzero",
      d: "M238.933 563.2L238.933 119.467 119.467 131.731 119.467 231.916 154.293 231.916 154.293 554.652zM562.746 119.467c35.885 0 64.503 15.083 86.76 45.879 22.259 30.796 33.16 69.762 33.16 116.898 0 35.195-7.722 72.275-23.166 111.241l-63.14 160.892-88.578 8.823 51.784-135.148c-5.45.628-9.54 1.257-12.719 1.257-29.526 0-54.51-14.455-74.042-42.737-19.533-28.282-29.072-63.477-29.072-105.585 0-43.366 10.902-81.075 32.706-113.127 22.258-32.053 51.33-48.393 86.307-48.393zm.124 100.465c-35.383-3.144-53.528 67.284-29.032 99.354 7.712 10.69 17.692 16.349 29.032 16.349 11.795 0 21.774-5.66 29.486-16.35 7.712-11.947 11.794-25.152 11.794-40.872 0-32.699-17.691-58.48-41.28-58.48z",
      transform: "translate(-136 -170) translate(136.533 170.667)"
    })])], -1)])),
    _: 1
  });
}