import apiClient from "@/api/api-client";
import LocalStorageService from "@/services/LocalStorageService";
const localStorageService = LocalStorageService.getService();
export const login = async (username, password) => {
  const result = await apiClient.post("/auth", {
    username,
    password
  });
  let {
    token,
    refresh_token
  } = result.data;
  console.log(token, refresh_token);
  localStorageService.setToken({
    access_token: token,
    refresh_token: refresh_token
  });
};
export const whoami = async () => {
  try {
    const {
      data
    } = await apiClient.get("/auth/me");
    return data.me;
  } catch (e) {
    return null;
  }
};