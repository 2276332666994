import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "fixed w-full h-full bg-white top-0 left-0 z-50 min-h-screen flex items-center justify-center bg-gray-100 text-gray-900"
};
const _hoisted_2 = {
  class: "px-5 py-6 lg:px-6 lg:py-8 w-full md:w-8/12 lg:w-6/12 xl:w-4/12 relative"
};
const _hoisted_3 = {
  class: "rounded border p-6 lg:p-10 shadow-sm bg-white"
};
const _hoisted_4 = {
  class: "block text-gray-700"
};
const _hoisted_5 = {
  key: 0,
  class: "block text-gray-700 mt-4"
};
const _hoisted_6 = {
  class: "text-right"
};
const _hoisted_7 = {
  class: "mt-6"
};
const _hoisted_8 = ["disabled"];
const _hoisted_9 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[6] || (_cache[6] = _createElementVNode("div", {
    class: "absolute right-0 bottom-0 left-0"
  }, [_createElementVNode("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 -100 1440 320"
  }, [_createElementVNode("path", {
    fill: "#EDF2F7",
    "fill-opacity": "1",
    d: "M0,96L26.7,112C53.3,128,107,160,160,181.3C213.3,203,267,213,320,192C373.3,171,427,117,480,122.7C533.3,128,587,192,640,229.3C693.3,267,747,277,800,245.3C853.3,213,907,139,960,138.7C1013.3,139,1067,213,1120,224C1173.3,235,1227,181,1280,154.7C1333.3,128,1387,128,1413,128L1440,128L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"
  })])], -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, [_createElementVNode("label", _hoisted_4, [_createTextVNode(_toDisplayString(_ctx.frgPassShow ? "Почта" : "Логин") + " ", 1), _createVNode(_component_el_input, {
    class: "mt-2",
    modelValue: _ctx.username,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.username = $event),
    placeholder: "Логин"
  }, null, 8, ["modelValue"])]), !_ctx.frgPassShow ? (_openBlock(), _createElementBlock("label", _hoisted_5, [_cache[5] || (_cache[5] = _createTextVNode(" Пароль ")), _createVNode(_component_el_input, {
    class: "mt-2",
    "show-password": "",
    type: "password",
    modelValue: _ctx.password,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.password = $event),
    placeholder: "Пароль",
    onKeydown: _withKeys(_withModifiers($options.login, ["prevent"]), ["enter"])
  }, null, 8, ["modelValue", "onKeydown"])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_button, {
    onClick: _cache[2] || (_cache[2] = $event => _ctx.frgPassShow = !_ctx.frgPassShow),
    type: "text"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.frgPassShow ? "Назад" : "Забыли пароль ?"), 1)]),
    _: 1
  })]), _createElementVNode("div", _hoisted_7, [_ctx.frgPassShow ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    disabled: _ctx.loading,
    onClick: _cache[3] || (_cache[3] = (...args) => $options.forgotPassword && $options.forgotPassword(...args)),
    type: "submit",
    class: "px-4 py-3 bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline block w-full"
  }, " Отправить пароль на почту ", 8, _hoisted_8)) : (_openBlock(), _createElementBlock("button", {
    key: 1,
    disabled: _ctx.loading,
    onClick: _cache[4] || (_cache[4] = (...args) => $options.login && $options.login(...args)),
    type: "submit",
    class: "px-4 py-3 bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline block w-full"
  }, " Войти ", 8, _hoisted_9))])])])])]);
}