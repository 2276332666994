import "core-js/modules/es.array.push.js";
import { login, whoami } from "@/api/auth";
import { forgotPasswordByLogin } from "@/api/users";
export default {
  name: "index",
  data: () => ({
    username: null,
    password: null,
    loading: false,
    frgPassShow: false,
    forgotForm: ""
  }),
  methods: {
    async forgotPassword() {
      this.loading = true;
      try {
        let data = await forgotPasswordByLogin(this.username);
        if (data._success) {
          this.$store.dispatch("notify/add", {
            type: "success",
            message: "Отправлено в вашу почте ссылку для сброса пароля",
            size: "mini"
          });
          this.username = "";
          this.frgPassShow = false;
        }
      } catch (err) {
        console.log(err);
        this.$store.dispatch("notify/add", {
          type: "error",
          message: "Произошла ошибка"
        });
      }
      this.loading = false;
    },
    async login() {
      this.loading = true;
      try {
        await login(this.username, this.password);
        this.$message({
          title: "Success",
          message: "Успешно",
          type: "success"
        });
        const user = await whoami();
        this.$store.commit("user/setUser", user);
        this.$router.push({
          name: "Home"
        });
      } catch (e) {
        this.$message.error(e.message);
      }
      this.loading = false;
    }
  }
};