import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "notification-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_notification = _resolveComponent("notification");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, notification => {
    return _openBlock(), _createBlock(_component_notification, {
      key: notification.id,
      notification: notification
    }, null, 8, ["notification"]);
  }), 128))]);
}