import axios from "axios";
import LocalStorageService from "@/services/LocalStorageService";
import store from "@/store/index";
const apiClient = axios.create({
  baseURL: window.domain
});
const localStorageService = LocalStorageService.getService();
apiClient.interceptors.request.use(config => {
  const token = localStorageService.getAccessToken();
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
}, error => {
  Promise.reject(error);
});
apiClient.interceptors.response.use(response => {
  if (response.data._success) {
    if (response.config.method === "post") {
      store.dispatch("notify/add", {
        type: "success",
        message: "Успешно",
        size: "mini"
      });
    }
  } else {
    if (response.config.method === "post" && "_success" in response.data) {
      let message = response.data.message || "Ошибка";
      store.dispatch("notify/add", {
        type: "error",
        message: message
      });
    }
  }
  return response;
}, function (error) {
  const originalRequest = error.config;
  console.log(error.response.status, originalRequest.url);
  if (error.response.status === 401 && originalRequest.url === `auth/refresh`) {
    console.log("ERROR");
    localStorageService.clearToken();
    location.hash = "#/auth";
    return Promise.reject(error);
  }
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const refreshToken = localStorageService.getRefreshToken();
    return apiClient.post("auth/refresh", {
      refresh_token: refreshToken
    }).then(res => {
      console.log(res);
      let {
        token
      } = res.data;
      localStorageService.setAccessToken({
        access_token: token
      });
      apiClient.defaults.headers.common["Authorization"] = "Bearer " + localStorageService.getAccessToken();
      return apiClient(originalRequest);
    });
  }
  return Promise.reject(error);
});
export default apiClient;