import "core-js/modules/es.array.push.js";
export default {
  computed: {
    user() {
      return this.$store.state.user.user;
    }
  },
  mounted() {
    if (this.user) {
      let state = "all";
      if (this.user.scopes.includes("annotator")) {
        state = 0;
      } else if (this.user.scopes.includes("verifier")) {
        state = 2;
      }
      this.$router.push({
        name: "files-list",
        params: {
          state
        }
      });
    }
  }
};