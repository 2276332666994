import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_notification_container = _resolveComponent("notification-container");
  const _component_privatetLayout = _resolveComponent("privatetLayout");
  const _component_publicLayout = _resolveComponent("publicLayout");
  return !$data.loading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_notification_container), $options.isLoggedIn ? (_openBlock(), _createBlock(_component_privatetLayout, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_publicLayout, {
    key: 1
  }))])) : _createCommentVNode("", true);
}