import privatetLayout from "@/layout/private";
import publicLayout from "@/layout/public";
import notificationContainer from "@/components/notification/notification-container.vue";
import { whoami } from "@/api/auth";
export default {
  components: {
    privatetLayout,
    publicLayout,
    notificationContainer
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/isLoggedIn"];
    }
  },
  async mounted() {
    const user = await whoami();
    this.loading = false;
    this.$store.commit("user/setUser", user);
  }
};